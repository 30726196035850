<template>
  <v-container fluid class="pa-6">
    <v-row no-gutters align="center" class="pa-2"
      ><v-col cols="12" md="6" align="start"
        ><h2>
          <v-icon color="primary" large left>{{ icon }}</v-icon
          >{{ title }} <span v-if="totalCount">({{ totalCount }})</span>
        </h2> </v-col
      ><v-col cols="12" md="6" align="end">
        <v-btn
          v-if="userCan('create-' + [routeName])"
          class="primary--text"
          large
          :to="{ name: 'create-' + [routeName] }"
          >Add new <v-icon right color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-col></v-row
    >

    <v-data-iterator
      :items="items"
      :options="pagination"
      :items-per-page.sync="pagination.rowsPerPage"
      :server-items-length.sync="totalCount"
      :page.sync="pagination.page"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions
      }"
    >
      <template v-slot:header v-if="!showDataOnly">
        <v-toolbar dark color="primary " class="mb-1 my-4 ">
          <v-text-field
            v-model="pagination.search"
            clearable
            flat
            solo-inverted
            hide-details
            class="mx-4"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            placeholder="Search"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-select
              v-model="pagination.orderBy"
              flat
              solo-inverted
              hide-details
              class="mx-4"
              :items="keysForSorting"
              prepend-inner-icon="mdi-sort"
              label="Sort by"
              placeholder="Sort by"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="pagination.sortDesc" mandatory>
              <v-btn large depressed color="primary" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="primary" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>
      >
      <template v-slot:no-data>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
            ><v-toolbar-title>Info</v-toolbar-title></v-toolbar
          >
          <v-alert
            border="bottom"
            colored-border
            color="primary"
            elevation="2"
            type="info"
          >
            No data available
          </v-alert>
        </v-card>
      </template>
      >
      <template v-slot:loading>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
            ><v-toolbar-title>Loading data...</v-toolbar-title></v-toolbar
          >
          <v-alert border="bottom" colored-border color="primary" elevation="2">
            <v-progress-circular indeterminate color="primary" />
          </v-alert>
        </v-card>
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="(item, index) in props.items"
            :key="item.name + index"
            class="pa-4"
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card class="dekra-card" :class="{ 'on-hover': hover }">
                <v-card-title class="subheading font-weight-bold">
                  <v-row align="center">
                    <v-col
                      cols="10"
                      class="text-left text-truncate"
                      v-if="item.name"
                    >
                      <span>{{ item.name }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                      <v-avatar v-if="item.preview_url" size="56" tile
                        ><v-img contain :src="item.preview_url"></v-img
                      ></v-avatar>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider></v-divider>

                <template v-if="item.file_type">
                  <v-list dense>
                    <v-list-item v-if="item.file_type">
                      <v-list-item-content>File type:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.file_type["display_name"] }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                </template>
                <v-card-actions>
                  <div class="caption mx-2" v-if="item['created_at']">
                    {{ formatDate(item["created_at"]) }}
                  </div>
                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    class="error--text"
                    large
                    @click="
                      confirmDeleteDialog = true;
                      currentId = item.id;
                    "
                  >
                    <v-icon right color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    v-if="item.preview_url"
                    class="primary--text"
                    large
                    :link="true"
                    target="_blank"
                    :href="item.preview_url"
                  >
                    <v-icon right color="primary">mdi-download</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="310">
      <v-card>
        <v-card-title class="error headline white--text"
          >Confirm Delete</v-card-title
        >
        <v-card-text class="body-1 pa-3"
          >Are you sure you want to delete this item?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            large
            @click.native="confirmDeleteDialog = false"
          >
            <v-icon size="20" left>mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-btn
            color="error"
            outlined
            large
            @click.native="deleteItem"
            v-if="userCan('delete-' + [routeName])"
          >
            <v-icon size="20" left>mdi-trash-can-outline</v-icon>Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import file from "@/mixins/file";
import permission from "@/mixins/permission";
export default {
  mixins: [date, file, permission],
  name: "FileList",
  props: {
    routeName: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true,
      default: "Title"
    },
    icon: {
      type: String,
      required: true,
      default: "mdi-help"
    },
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      confirmDeleteDialog: false,
      currentId: null,
      itemsPerPageOptions: [12, 24, 48, -1]
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters[this.namespace + "/loading"];
      }
    },
    totalCount: {
      get() {
        return this.$store.getters[this.namespace + "/total"];
      }
    },
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/getItems", false);
      }
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + "/pagination"];
      },
      set(value) {
        this.$store.dispatch(this.namespace + "/setPagination", value);
      }
    },
    keysForSorting() {
      return [{ value: "created_at", text: "Created at" }];
    }
  },
  created() {
    this.getData();
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    deleteItem() {
      const _this = this;
      _this.$store
        .dispatch(_this.namespace + "/deleteItem", this.currentId)
        .then(() => {
          this.confirmDeleteDialog = false;
          this.currentId = null;
          this.getData();
        });
    },
    async getData() {
      await this.$store.dispatch(this.namespace + "/getItems", false);
    },
    getItemValue(item, name) {
      if (name.includes(".")) {
        let nameArray = name.split(".");
        return item[nameArray[0]][nameArray[1]];
      }
      return item[name];
    }
  }
};
</script>
<style scoped>
.dekra-card {
  border-bottom: 5px solid #017d40;
  transition: transform 0.2s;
}
.dekra-card.on-hover {
  transform: scale(1.04);
}
</style>
